<template>
    <div class="grouplist">
        <div class="padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">群名称：</div>
                    <el-input v-model="company_name" placeholder="请输入群名称"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">群列表</div>
                <el-button type="primary" class="xinqiye" icon="el-icon-plus" @click="onNewGroup('')">新增群</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="companyList" style="width: 100%">
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column prop="company_name" label="群名称"></el-table-column>
                <el-table-column prop="created_at" label="创建时间"></el-table-column>
                <el-table-column prop="updated_at" label="更新时间"></el-table-column>
                <el-table-column prop="id" label="操作">
                    <template slot-scope="scope">
                        <div class="operation">
                            <el-button type="primary" plain @click="onNewGroup(scope.row.id,scope.row.company_name)">编辑</el-button>
                            <el-button type="primary" @click="onConfigGroup(scope.row.id)">配置</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="ongetTopCompanyList" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
        <!-- 新增群 -->
        <Newgroup ref="Newgroup"></Newgroup>
        <ConfigGroup ref="ConfigGroup"></ConfigGroup>
    </div>
</template>

<script>
import Newgroup from "./newgroup.vue";
import ConfigGroup from "./configGroup.vue";
export default {
    
    data() {
        return {
            paramData:{},//表格参数
            company_name: "",
            companyList: [], //群列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
        };
    },
    components: {
        Newgroup,
        ConfigGroup,
    },

    mounted() {
        this.ongetTopCompanyList();
    },
    methods: {
        //搜索
        onButtonInquire() {
            this.paramData = {
                company_name: this.company_name,
            }
            this.paramPage = 1
            this.ongetTopCompanyList()
        },
        //群列表
        ongetTopCompanyList() {
            this.companyList = [];
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.companyList = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetTopCompanyList()
        },
        // 新增群 || 修改群
        onNewGroup(company_id, company_name) {
            this.$refs.Newgroup.onnewDialog(company_id, company_name);
        },
        // 群配置
        onConfigGroup(company_id) {
            this.$refs.ConfigGroup.onnewDialog(company_id);
        },
        //清空搜索掉接口 (弹窗回调专用)
        ongetTop() {
            this.company_name = "";
            this.ongetTopCompanyList(1);
        },
    },


};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>