import { render, staticRenderFns } from "./newgroup.vue?vue&type=template&id=facb24a8&scoped=true&"
import script from "./newgroup.vue?vue&type=script&lang=js&"
export * from "./newgroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "facb24a8",
  null
  
)

export default component.exports