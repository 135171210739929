<template>
	<!-- 新增群 -->
    <div class="configGroup">
		<el-dialog title="群配置" :visible.sync="newGronpDialog" width="720px"  class="class-dialog" :before-close='onCancel' modal-append-to-body>
			<div class="dialog-inside ">
				<div class="sunbox distryof">
					<div class="sunbox-text"><span class="text-xing">*</span>账户</div>
					<div class="sunbox-content">
						<div class="znbotton">
							<el-select v-model="AccountofInput" @change="AccountChange" placeholder="请输入需要搜索的账户" value-key="id" filterable remote :remote-method="AccountgenerateData">
								<el-option
									v-for="item in AccountofList"
									:key="item.value"
									:label="item.agent_account_name + ' _ ' + item.agent_account + ' _ ' + (item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : '渠道')"
									:value="item">
								</el-option>
							</el-select>
							<div class="increase">
								<el-button type="primary" icon="el-icon-plus" @click="onAddAccount"></el-button>
							</div>
						</div>
						<div class="UserexList" v-if="AccountofListHave.length">
							<div class="Useryiyou" v-for="(item,key) in AccountofListHave" :key="key">
								<i class="el-icon-delete" @click="AccountofDel(key)"></i>
								{{ item.agent_account_name}} ({{item.agent_account}})
								<span>（{{item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : '渠道'}}）</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onCancel" class="button-Dialog">取 消</el-button>
				<el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
			</div>
        	<AddAccount ref="AddAccount"></AddAccount>
		</el-dialog>
	</div>
</template>

<script>
import AddAccount from "../index/Account/AddAccount.vue";
export default {
	data(){
        return{
            newGronpDialog:false,
			company_id:'',

			//账户
			AccountofInput:'',//企业名搜索
			AccountofList:[],//企业列表
			AccountofListHave:[],//已分配的企业列表
			AccountofPostList:[],
        }
    },

	components: {
        AddAccount,
    },

	mounted(){
		
	},

	

	methods:{
		//新增账户弹窗
		onAddAccount(){
            this.$refs.AddAccount.onzhanghu()
		},
		// 群配置弹窗
		onnewDialog(company_id){
			this.company_id = company_id
			this.AccountgenerateData()
			this.AccountgenerateDatato()
			this.newGronpDialog = true
		},
		//提交
		onSubmit(){
			var param = {
				company_id:this.company_id,
				agent_account:this.AccountofPostList
			}
			console.log(param)
			this.$service.post(this.$api.distributionTopCompanyAgentConfig,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '配置群成功',type: 'success'});
					this.onCancel()
				}
			})
		},
		//取消
		onCancel(){
			this.newGronpDialog = false
			this.company_id = '',

			//账户
			this.AccountofInput = '',//账户名搜索
			this.AccountofList = [],//账户列表
			this.AccountofListHave = [],//已分配的账户列表
			this.AccountofPostList = []
		},



		//账户管理
		// 选中账户时
		AccountChange(){
			var mess = true
			this.AccountofListHave.forEach((v,k) => {
				if(this.AccountofInput.id == v.id){
					return mess = false
				}
			});
			if(mess){
				this.AccountofListHave.unshift(this.AccountofInput)
				this.onAccountofPostList()
			}else{
				this.$message({message: '您已选择过这个用户',type: 'warning'});
			}
		},
		onAccountofPostList(){
			this.AccountofPostList = []
			this.AccountofListHave.forEach((v,k) => {
				var obj = {agent_account_id:v.id}
				this.AccountofPostList.push(obj)
			});
		},
		//删除选过的账户
		AccountofDel(key){
			this.AccountofListHave.splice(key,1)
			this.onAccountofPostList()
		},
		//调用账户列表
		AccountgenerateData($text){
			// this.baoliu = []
			var param = {
				checked_company_id:this.company_id,
				agent_account:$text
			}
			this.$service.get(this.$api.getTopAgentAccountList,param, (res)=> {
				if(res.code == '200'){
					this.AccountofList = res.data.data
					this.onAccountofPostList()
				}
			})
		},
		//掉接口已有账户列表
		AccountgenerateDatato(){
			// this.baoliu = []
			var param = {
				checked_company_id:this.company_id,
			}
			this.$service.get(this.$api.getTopAgentAccountList,param, (res)=> {
				if(res.code == '200'){
					this.AccountofListHave = res.data.is_checked
					this.onAccountofPostList()
				}
			})
		},
    },


}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.configGroup{
	.sunbox-content{
		.Useryiyou{
			width: 500px;
		}
		.znbotton{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.el-input{
				width: 430px;
			}
		}
	}
}
</style>