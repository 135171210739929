<template>
    <!-- 弹窗企业选择--单选 -->
    <div>
        <el-select v-model="operValue" @change="onModifyOpenValue" filterable :remote-method="onOperRemoteMethod" remote reserve-keyword placeholder="请选择企业" value-key="id" style="width:100%">
            <!-- <div v-infinite-scroll="load" style="" class="flock-load"> -->
                <el-option
                    v-for="item in operOptions"
                    :key="item.value"
                    :label="item.corporate_account_name"
                    :value="item">
                </el-option>
            <!-- </div> -->
        </el-select>
        <div class="UserexList" v-if="operListHave.id">
            <div class="Useryiyou">
                <i class="el-icon-s-custom"></i>
                {{ operListHave.corporate_account_name}} 
                <!-- <span>（{{operListHave[0].operating_mobile ? operListHave[0].operating_mobile : '暂未绑定手机号'}}）</span> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[
        'company_id'//群id，编辑群时必填，获取群管理企业（搜索用到）
    ],
    data() {
        return {
            operValue:{},//选择后的企业
			operOptions:[],//企业列表
			operPage:1,//企业下拉加载页数
			operListHave:{},//企业初始选中
			corporate_account_name:'',//企业名称（搜索用到）
			// company_id:'',//群id，编辑群时必填，获取群管理企业（搜索用到）
        };
    },
    mounted() {
    },
    methods: {
        //企业搜索触发事件修改企业选中数据
		onModifyOpenValue(){
			this.operListHave = this.operValue
			this.operValue = {}
		},
        //企业搜索
		onOperRemoteMethod($text){
			this.operPage = 1
			this.operOptions = []
			this.ongetTopCorporateAccountList($text)
		},
        ongetTopCorporateAccountList(text){
			this.corporate_account_name = text
			var prame = {
				page:this.operPage,
				corporate_account_name:this.corporate_account_name,
				company_id:this.company_id
			}
			this.$service.get(this.$api.getTopCorporateAccountList,prame, (res)=> {
				if(res.code == '200'){
                    // var operOptions = this.operOptions
                    // res.data.data.forEach((v,k) => {
                    //     operOptions.push(v)
                    // });
                    this.operOptions = res.data.data
                    if(!this.operListHave.id){
                        this.operListHave = res.data.is_checked && res.data.is_checked.id ? res.data.is_checked : {}
                    }
				}
			})
		},
        //清空本次数据
        onempty(){
            this.operValue = {},//选择框里的企业
			this.operOptions = [],//企业列表
			this.operPage = 1,//企业下拉加载页数
			this.operListHave = {},//选择后确认的企业列表
			this.corporate_account_name = ''//企业名称（搜索用到）
            // console.log(this.operValue,'选择框里的企业')
            // console.log(this.operPage,'企业下拉加载页数')
            // console.log(this.operListHave,'选择后确认的企业列表')
            // console.log(this.corporate_account_name,'企业名称（搜索用到）')
        }
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getAdvertiserList,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
</style>