<template>
	<!-- 新增群 -->
    <div class="newgroup">
		<el-dialog :title="company_id == '' ? '新增群' : '编辑群'" :visible.sync="newGronpDialog" width="500px" class="class-dialog" :before-close='onCancel' @opened="onOpened">
			<div class="dialog-inside ">
				<div class="sunbox">
					<div class="sunbox-text"><span class="text-xing">*</span>选择企业</div>
					<div class="sunbox-content">
						<EnterprisePopup ref="EnterprisePopup" :company_id='company_id'></EnterprisePopup>
						<!-- <el-input v-model="company_name" placeholder="请输入群名称"></el-input> -->
					</div>
				</div>
				<div class="sunbox">
					<div class="sunbox-text"><span class="text-xing">*</span>群名称</div>
					<div class="sunbox-content">
						<el-input v-model="company_name" placeholder="请输入群名称"></el-input>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="onCancel" class="button-Dialog">取 消</el-button>
				<el-button type="primary" @click="onSubmit" class="button-Dialog">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
// import EnterprisePopup from "@/views/commonSearch/enterprisePopup.vue"; //企业选择框
import EnterprisePopup from "../commonSearch/enterprisePopup.vue"; //企业选择框
export default {
	data(){
        return{
            newGronpDialog:false,
			company_name:'123123',
			company_id:'',
        }
		
    },
	components: {
        EnterprisePopup
    },
	mounted(){
	},
	methods:{
		// 打开动画结束时回调
		onOpened(){
			// console.log(11111)
			this.$refs.EnterprisePopup.ongetTopCorporateAccountList();
		},
		// 新增群弹窗
		onnewDialog(company_id,company_name){
			this.company_id = company_id
			this.company_name = company_name
			this.newGronpDialog = true
		},
		//提交
		onSubmit(){
			if(this.company_name == '' && this.company_id != ''){this.$message({message: '请输入群名称',type: 'warning'}); return}
			var param = {
				company_id : this.company_id,
				company_name:this.company_name,
				corporate_account_id:this.$refs.EnterprisePopup.operListHave.id//企业id
			}
			this.$service.post(this.$api.createTopCompany,param, (res)=> {
				if(res.code == '200'){
					this.company_id == '' ? this.$message({message: '添加成功',type: 'success'}) : this.$message({message: '修改成功',type: 'success'})
					this.onCancel()
					// this.$parent.ongetTop(1)
					// this.newGronpDialog = false
				}
			})
		},
		//取消
		onCancel(){
			this.company_name = ''
			this.company_id = ''
			this.$refs.EnterprisePopup.onempty()
			this.newGronpDialog = false
		}
    },

}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">

</style>